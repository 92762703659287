import React, { useEffect, useState, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { BasicLayout, NoPermission } from '@components/index'
import { asyncSetCityTreeData, asyncSetLabelTreeData, asyncSetDeptWithUserTreeData, asyncSetUserInfo, asyncSetLqSearchConditionData, asyncSetGetConsumeOrderProcess, asyncSetDownLoadUrlMap, asyncSetSysNotFollowDay } from '@store/actions'
import { getStorage, parseSearch, TOKENKEY, treeDataToFlatArr } from '@utils/index';
import { MenuRouter } from '@router/index'
import { Spin } from 'antd';

export default (props: any) => {
    const [loading, setLoading] = useState<boolean>(true)
    const history = useHistory()
    const { pathname, search } = useLocation();
    const dispatch = useDispatch();
    const { RouterView } = props
    const { fromPath } = parseSearch(search)
    const handleLogin = async() => {
        setLoading(true)
        // const res = await Promise.all([dispatch(asyncSetUserInfo()), dispatch(asyncSetDeptWithUserTreeData())])
        // console.log(res)
        const { permissions } = await dispatch(asyncSetUserInfo()) as any // 获取账号权限信息
        await dispatch(asyncSetDeptWithUserTreeData()) // 获取组织架构(含员工)数据
        await dispatch(asyncSetCityTreeData()) // 获取省市区数据
        await dispatch(asyncSetLabelTreeData()) // 获取自定义标签数据
        await dispatch(asyncSetLqSearchConditionData()) // 获取猎奇查询条件数据
        await dispatch(asyncSetGetConsumeOrderProcess()) // 查询消费订单后端处理情况
        await dispatch(asyncSetDownLoadUrlMap()) // 设置模版下载url 映射
        // await dispatch(asyncSetSysNotFollowDay()) // 存储系统中设置的 跟进规则
        setLoading(false)
        const willTo = calcWillToPath(permissions||[])
        history.push(willTo)
    }

    const calcWillToPath = (permissions: any[]) => {
        const flatArr = treeDataToFlatArr(MenuRouter, false)
        const len = (permissions||[]).length
        const curPathnameCode = (flatArr.find(v => v.path === pathname)||{}).authCode
        const hasCurrentPathnameAuth = (permissions||[]).includes(curPathnameCode)
        if (['/login'].includes(fromPath) || ['/'].includes(pathname)) { // 从登陆页过来的
            if (['/'].includes(pathname)) {
                return '/home'
            }
            const toPath = len ? (flatArr.find(v => v.authCode === permissions[0])||{}).path : '/noPermission'
            return toPath
        }
        if (hasCurrentPathnameAuth || [-1].includes(curPathnameCode)) {
            return `${pathname}${search}`
        }
        return '/noPermission'
    }

    useEffect(() => {
        const isLogin = getStorage(TOKENKEY)
        if (isLogin) {
            handleLogin()
        } else {
            history.push('/login')
        }
    }, [])

    const MemoBasicLayout = useMemo(() => <Spin spinning={loading}><BasicLayout RouterView={ !loading ? RouterView : null} /></Spin>, [RouterView,loading])
    return MemoBasicLayout
}
