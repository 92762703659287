import React, { useEffect } from 'react'
import { Form, Input, Button, Checkbox, Popover  } from 'antd'
import './index.less'
import { login as loginApi } from '@api/index'
import { setStorage, removeStorage, getStorage, fromData, TOKENKEY } from '@utils/index'
import bgSmall from '@assets/images/bg-small.png'
import { useHistory } from 'react-router-dom'
import { stringify } from 'qs'
import { Icon } from '..'

const { Item } = Form

// 登录表单拆解
const LoginForm = ({onSubmit, ...rest}: any) => {
    const [form] = Form.useForm();
    const submit = (values: {remembered: boolean; username: string; password: string }) => {
        const {remembered} = values
        remembered ? setStorage('loginForm', JSON.stringify(values||'{}')) : removeStorage('loginForm')
        onSubmit && onSubmit(values)
    }
    useEffect(() => {
        const { remembered, ...rest } = JSON.parse(getStorage('loginForm')||'{}')
        form.setFieldsValue({ remembered, ...rest})
    }, [])
    return (
        <div className="login-form"> 
            <div className="titleDiv">
              <h1>CUSTOMER OS</h1>
              <h1 style={{fontSize: '36px'}}>聚能石客户运营系统</h1>
            </div>
            <Form form={form} onFinish={submit} {...rest}>
                <Item name="username" rules={[{ required: true, message: '请输入账号!' }]}>
                    <Input allowClear placeholder="账号" prefix={<Icon type="icon-gerenzhongxin" style={{ color: 'rgba(0,0,0,.25)' }} />}/>
                </Item>
                <Item name="password" rules={[{ required: true, message: '请输入密码!' }]}>
                    <Input.Password placeholder="密码" prefix={<Icon type="icon-password" style={{ color: 'rgba(0,0,0,.25)' }} />}/>
                </Item>
                <Item>
                    <Item name="remembered" valuePropName="checked" noStyle><Checkbox>记住密码</Checkbox></Item>
                    <Popover content={'请联系管理员重置密码'} title=""><a style={{float: 'right'}}>忘记密码</a></Popover>
                </Item>
                <Item>
                    <Button type="primary" size="large" htmlType="submit" style={{ width: '100%', textAlign: 'center' }} className="login-form-button">登录</Button>
                </Item>
            </Form>
        </div>
    )
}
export default () => {
    const history = useHistory()
    const onSubmit = async (values: any) => {
        const { success, data } = await loginApi(fromData(values)) as any
        const { token } = data || {}
        if (success) {
           setStorage(TOKENKEY, token)
           history.push(`/home?${stringify({fromPath: '/login'})}`)
        }
    }
    return <div className="loginBg">
        <div className="mainDiv">
            <LoginForm onSubmit={onSubmit} />
            <img className="image-logo" src={bgSmall} alt="" />
            <div style={{ width: '100%', position: 'absolute', bottom: '-50px', left: '0', textAlign: 'center', color: 'white' }}>copyright © 2019 卓见云科技出品</div>
        </div>
    </div>
}
