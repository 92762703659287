import { BASEURL } from '@api/index'
import React, { useEffect,useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { ExcelExt, getStorage, ImgExt, imgUrlReg, PdfExt, productApi, TOKENKEY } from "@utils/index"
export { default as MultiIcon } from './MultiIcon'
export { default as MultiLabel } from './MultiLabel'
import { Image } from 'antd';
import '../index.less'
import axios from 'axios'

type dataType = {
    data: {
        url?: string,
        type: number, //1,富文本内容，2上传的文件链接内容预览，
        DocText?:string,
        title?: string, //创建文档的标题
    }
}

const getPdfUrl = async(params: any) => {
    const res = await axios({
        baseURL: `${BASEURL}/knowledgeBase/onlinePreview`,
        withCredentials: true,
        method: 'POST',
        data: params,
        timeout: 36000, // 请求超时时间
        responseType: 'arraybuffer',
        headers: {
            Authorization: 'Bearer ' + getStorage(TOKENKEY),
            'Content-Type': 'application/json; charset=utf-8'
        },
    })
    const blob = new window.Blob([res.data], {
        type: 'application/pdf;charset-UTF-8',
    });
    return URL.createObjectURL(blob)
}

const PreviewOnLine = ({href}: any) => {
    const [pdfUrl, setPdfUrl] = useState('')
    const isPdf = PdfExt.test(href)
    const isImg = imgUrlReg.test(href)

    const getUrl = async ()=> {
        if (isPdf || isImg) {
            setPdfUrl(href)
         } else {
            const url = await getPdfUrl({url: href})
            setPdfUrl(url)
         }
    }

    useEffect(()=> {
        getUrl()
    }, [])
    
    // 图片预览
    if (imgUrlReg.test(href)) {
        return <Image.PreviewGroup>
                <Image src={href} />
            </Image.PreviewGroup>
    }
    // 【pdf, word, ppt, excel】预览
    return(<iframe src={pdfUrl} style={{width: '100%', height: '780px', border: 'none'}}></iframe>)
}

export const PreviewDoc = ({__html, isLink}: any) => {
    return(<div>
        {isLink ? <PreviewOnLine href={__html} /> : <div className="editor-content-view" dangerouslySetInnerHTML={{__html}}></div>}
    </div>)
}