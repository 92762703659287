import React, { ReactNode, useEffect, useRef, useState, useMemo } from 'react'
import { Badge, Dropdown, Layout, Menu, Avatar, MenuProps, notification, Tooltip, Popover, Input, Select, Button } from 'antd'
import { useHistory, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import './index.less'
import { WaterMark } from '@ant-design/pro-layout'
import { StateType } from '@store/index'
import img from '@assets/images/gooutimg.png';
import logoImg from '@assets/images/logo.png';
import { removeStorage, getPathStage, parseSearch, treeDataToFlatArr, productApi, getToken } from '@utils/util';
import { MenuRouter, MenuItem } from '@router/index'
import { Breadcrumb, Icon } from '@components/index'
import { logout as logoutApi, oaGetmyMsg } from '@api/index'
import { useServerTaskProcess, useMyGetMessage as AsyncMessageNotice, useHasAuthCode, useClearCache } from '@hooks/index';
import { stringify } from 'qs';
import { useCacheDispatch, } from 'react-keepalive-router'

import {
    SearchOutlined
  } from '@ant-design/icons';
import { TOKENKEY } from '@utils/enum';
const { Search } = Input

const getApi = productApi(oaGetmyMsg)

type BasicLayoutProps = {
    RouterView?: ReactNode;
}
interface RenderMenusProps extends MenuProps{
    menus?: MenuItem[];
}
interface CustomSiderProps extends RenderMenusProps{
    
}

type CustomHeaderProps = {
    name?: string;
    msgCount?: number;
}

const { Header, Sider, Content } = Layout
const { SubMenu } = Menu

const OverlayMenu = () => {
    const dispatch = useCacheDispatch()
    const history = useHistory()
    const overlayHandle = async (e: any, type: string) => {
        if (type === 'loginOut') {
            removeStorage(TOKENKEY)
            await logoutApi()
            dispatch({ type:'reset',})
            history.push('/login')
        } else if (type === 'personal') {
            history.push('/user/personal')
        }
    }
    return (
        <Menu className="basic-layout-overlay">
            <Menu.Item style={{ width: '160px' }}>
                <div className="login-out" onClick={(e) => overlayHandle(e, 'personal')}>
                    <Icon type="icon-gerenzhongxin" className="login-out-icon" />个人中心
                </div>
            </Menu.Item>
            <Menu.Item style={{ width: '160px' }}>
                <div className="login-out">版本号：V4.0</div>
            </Menu.Item>
            <Menu.Item style={{ width: '160px' }}>
                <div className="login-out" onClick={(e) => overlayHandle(e, 'loginOut')}>
                    <Icon type="icon-tuichudenglu" className="login-out-icon" />退出登录
                </div>
            </Menu.Item>
        </Menu>
    )
}

const RenderMenus = (props: RenderMenusProps) => {
    const { menus, children, ...rest } = props
    const { userInfo } = useSelector((state: StateType) => state)
    const { permissions } = userInfo || {}

    const RenderMenuItems = (menus: MenuItem[] = []) => {
        return (menus||[]).map(({name, path, icon, children, authCode, hideInMenu}: MenuItem) => {
            const hasChildren = (children||[]).length
            const isRender = (authCode && (permissions||[]).includes(authCode)) || !authCode || [-1].includes(authCode as number)
            const MenuIcon = typeof icon === 'string' ?  <Icon type={icon} /> : icon
            if (isRender) {
                return !hideInMenu ? hasChildren ? <SubMenu key={path} icon={MenuIcon} title={name}>
                    {RenderMenuItems(children||[])}
                </SubMenu> : <Menu.Item className={ icon ? '' : 'leftMargin' } icon={MenuIcon} key={path}><Link to={path}>{ name as any }</Link></Menu.Item> : null  
            }
        })
    }
    return <Menu {...rest}>
        {RenderMenuItems(menus)}
        {children}
    </Menu>
}

const RenderMessage = () => {
    const [messageList, setMessageList] = useState([])
    const { messageNumber } = useSelector((state: StateType) => state)
    const getListApi = async () => {
        const { data } = await getApi({pageCurrent: 1, pageSize: 5, readStatus: "0"}, false)
        const { values } = data || {}
        setMessageList(values||[])
    }
    const content = (
        <div className='userMessage'>
            {
                (messageList||[]).map((element: any, index) => {
                    return <Link to={`/oa/messageDetail?${stringify({id: element.id, msgId: element.msgId, customerName: element.customerName, msgType: element.msgType})}`} key={index} >
                        <div className='messageItemDiv'>
                            <div className='messageText'>
                                {(element.msgTitle.length > 14) ? element.msgTitle.slice(0, 14) + '...' : element.msgTitle}
                            </div>
                            <div className='messageTime'>
                                {element.receiverTime}
                            </div>
                        </div>
                    </Link>
                })

            }
            {(messageList.length === 0) && <p style={{textAlign: 'center' }}>暂无未读消息</p>}
            <div className='goMyMessage'>
                <Link to="/oa/myMessage">
                    查看全部消息
                </Link> 
            </div>
        </div>
    );
    useEffect(()=> {
        getListApi()
    }, [])
   return (
        <Popover placement="bottomRight" title="未读消息" onVisibleChange={(val: any)=> val && getListApi()} content={content}>
            <Badge count={messageNumber} overflowCount={99}  size="small" style={{marginRight: '11px'}}>
                <Avatar style={{background: 'transparent', marginRight: '10px', marginTop: '6px'}} icon={<Icon className={ messageNumber ? 'flicker' : undefined} type="icon-xiaoxi1" style={{fontSize: '24px', color: 'orange', marginTop: '0px'}} />} size={24} />
            </Badge>
        </Popover>
    )
} 

const HomeSearch = () => {
    const history = useHistory()
    const { search } = useLocation()
    const { keyWord } = parseSearch(search)
    const searchRef = useRef<any>(null)
    const assign = (params: any) => {
        searchRef.current = {
            ...searchRef?.current,
            ...params
        }
    }
    const onSearch = () => {
        history.push(`/query?${stringify(searchRef.current)}`)
    }
    return(<Input.Group 
                compact 
                style={{
                    display: 'inline-block', 
                    width: 'auto', 
                    // background: '#FAFAFA', 
                    // background: '#1DBCA1',
                    borderRadius: '16px', 
                    // border: '1px solid #DDDDDD', 
                    border: '1px solid #1890ff', 
                    marginRight: 16,
                    overflow: 'hidden'
                }} >
                    <Input 
                        style={{ width: 372 }} 
                        // placeholder="输入关键词全局搜索" 
                        placeholder='请输入客户名称、完整的客户账号进行查询'
                        bordered={false}
                        defaultValue={keyWord}
                        // suffix={<SearchOutlined onClick={onSearch} style={{color: '#1DBCA1'}} />}
                        onChange={(e) => assign({keyWord: e.target?.value})} 
                        onPressEnter={onSearch}  
                    />
                    <Button type="primary" onClick={onSearch}>查客户</Button>
            </Input.Group>)
    // return(<Input.Group 
    //             compact 
    //             style={{
    //                 display: 'inline-block', 
    //                 width: 'auto', 
    //                 // background: '#FAFAFA', 
    //                 // background: '#1DBCA1',
    //                 borderRadius: '16px', 
    //                 // border: '1px solid #DDDDDD', 
    //                 border: '1px solid #1DBCA1', 
    //                 marginRight: 16,
    //                 overflow: 'hidden'
    //             }} >
    //                 <Select 
    //                     defaultValue={1} 
    //                     options={[{label: '客户名称', value: 1}, {label: '客户账号', value: 2}]} 
    //                     bordered={false}
    //                     onChange={(searchType) => assign({searchType})}
    //                     style={{background: '#1DBCA1', color: '#fff'}}
    //                 />
    //                 <Input 
    //                     style={{ width: 326 }} 
    //                     // placeholder="输入关键词全局搜索" 
    //                     placeholder='请输入客户名称、完整的客户账号进行查询'
    //                     bordered={false}
    //                     suffix={<SearchOutlined onClick={onSearch} style={{color: '#1DBCA1'}} />}
    //                     onChange={(e) => assign({keyWord: e.target?.value})} 
    //                     onPressEnter={onSearch}  
    //                 />
    //         </Input.Group>)
}

const ClearCache = () => {
    const reserveKeysRemove = useClearCache()
    const clearCache = () => {
        reserveKeysRemove([TOKENKEY])
        window.location.reload()
    }
    return (<Tooltip title="清除系统缓存"><Icon type="icon-qingchuhuancun" onClick={clearCache} style={{fontSize: '24px', marginTop: '3px', marginRight: 6, cursor: 'pointer'}} /></Tooltip>)
}

const CustomHeader = ({ name }: CustomHeaderProps) => {
    const [permissions, hasAuthCode] = useHasAuthCode()
    return (
        <Header className="basic-layout-header">
            { hasAuthCode(2384) ? <AsyncMessageNotice /> : null }
            <div style={{display: 'flex', alignItems: 'center'}}>
                <HomeSearch />
            </div>
            <div className="right-setting" style={{display: 'flex', alignItems: 'center'}}>
                <ClearCache />
                { hasAuthCode(2384) ? <RenderMessage /> : null }
                { hasAuthCode(2362) ? <Link to="/knowledge/home" style={{marginRight: '16px', display: 'inline-block'}}>
                    <Avatar style={{background: 'transparent'}} icon={<Tooltip title="知识社区"><Icon type="icon-zhishishequ" style={{fontSize: '24px', marginTop: '1px'}} /></Tooltip>} size={24} />
                </Link> : null }
                <Dropdown overlay={<OverlayMenu />}>
                    <a className="ant-dropdown-link" style={{display: 'inline-block'}}>
                        <Avatar src={img} size={28} />
                        <span className="header-right-account">{name || '暂无'}</span>
                    </a>
                </Dropdown>
            </div>
        </Header>
    )
}
const Logo = (props: any) => {
    return (
      <div className='logoDiv'>
          <img src={logoImg} className={props.state ? 'logo_img' : 'logo_img_only'} alt="" />
         { props.state ? <div className='logo_name'>聚能石</div> : null}
      </div>
    )
}

const CustomSider = (props: CustomSiderProps) => {
    const [ state, setState ] = useState<boolean>(false)
    return (
            <Sider style={{zIndex:999}} collapsible width={150} onCollapse={(broken) => setState(broken)}>
            <Logo state={!state}/>
            <RenderMenus mode="inline"  className='menuDiv' theme="dark" {...props} />
        </Sider>
    )
}

export default (props: BasicLayoutProps) => {
    const { RouterView } = props
    const { pathname, search } = useLocation();
    const { userInfo } = useSelector((state: StateType) => state) as any;
    const { user } = userInfo
    const { userName, name: nickName } = user || {}
    const name = userName || nickName || ''
    useServerTaskProcess()
    const calcSelectedKeys = () => {
        const flatArr = treeDataToFlatArr(MenuRouter, false)
        const { parentPath } = flatArr.find(({path}) => path === pathname) || {}
        const { fromPath } = parseSearch(search)
        return [ pathname, fromPath || parentPath]
    }

    return (
        <Layout className="basic-layout" >
            <CustomSider menus={MenuRouter} defaultOpenKeys={getPathStage(pathname)} selectedKeys={calcSelectedKeys()} />
            <Layout style={{minHeight: '100vh'}}>
                <CustomHeader name={name} />
                <div className='stickyDiv'>
                    <Breadcrumb/>
                </div>
                <WaterMark content={nickName} zIndex={8} gapY={80} gapX={100} fontColor='rgba(0,0,0,0.07)'>
                    <Content className='Content customScrollBar'>
                        <div className="main-container" style={{ height: '100%' }}>
                            {RouterView}
                        </div>
                    </Content>
                </WaterMark>
            </Layout>
        </Layout>
    )
}
