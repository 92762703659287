const enum TemplateUrl {
    '客户列表-批量导入客户' = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/352C1FBBCF54489DBB7B7B5082A67302_客户导入模板.xlsx',
    '发票审批-导入邮寄信息' = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/A76C9A40CE804C6EAC35116CFC9FFE1D_导入邮寄信息模板.xlsx',
    '发票审批-导入发票号码' = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/96751302BB3D4F379FBB4B004A0E1A70_导入发票号码模板.xlsx',
    '猎奇-批量查询' = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/E5773EB93AE94EF4A3132C286F57DC3B_批量导入模板（猎奇）.xlsx',
    '任务中心-新建任务-批量导入' = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/EEAEB90DE5CE4DF89F73F605512EFD64_导入客户-任务中心.xls',
    '返点列表-导入消费订单' = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88/%E6%B6%88%E8%B4%B9%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF20220516.xlsx',
    '返点列表-导入特殊返点' = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88/%E7%89%B9%E6%AE%8A%E8%BF%94%E7%82%B9%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
    '返点列表-导入打款表' = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88/%E5%BE%85%E6%89%93%E6%AC%BE%E8%A1%A8.xlsx',
}
export const TEMPLATEURL = {
    '客户列表-批量导入客户': TemplateUrl["客户列表-批量导入客户"],
    '发票审批-导入邮寄信息': TemplateUrl["发票审批-导入邮寄信息"],
    '发票审批-导入发票号码': TemplateUrl["发票审批-导入发票号码"],
    '猎奇-批量查询': TemplateUrl["猎奇-批量查询"],
    '任务中心-新建任务-批量导入': TemplateUrl["任务中心-新建任务-批量导入"],
    '返点列表-导入消费订单': TemplateUrl["返点列表-导入消费订单"],
    '返点列表-导入特殊返点': TemplateUrl["返点列表-导入特殊返点"],
    '返点列表-导入打款表': TemplateUrl["返点列表-导入打款表"]
}

// 云厂商枚举
export enum CLOUD_VENDOR {
    ALI = 54,
    HUAWEI = 367,
    TENCENT = 368
}

// 是否的枚举值
export const yesNoOptions = [
    {label: '是', value: 1},
    {label: '否', value: 0}
]


export const custOptions = [{label: '无', value: 0}]

// 系统常量
export const TOKENKEY = 'Admin-Token'
