import { useEffect, useState, useRef } from "react"
import { queryDeptTreeData, sysDictCitys, sysLabelList, queryDeptTreeDataWithRole, customerContactList, sysDictListRight, sysPoolList, sysRolelList, productTree, lqSearchConditions, getProductCategory, delImportExcelConsumeOrderStatus, customerGetMessage } from '@api/index'
import { getSession, productApi, removeSession, removeStorage, request, setSession, TOKENKEY, transferTreeData } from "@utils/index"
import { useDispatch, useSelector } from "react-redux"
import { StateType } from "@store/index"
import type { TreeDataItem, TransferApiData, DisabledRule, CustomerHooks, CommonApiHooks } from 'types'
import { asyncSetGetConsumeOrderProcess, asyncSetMessageList } from "@store/actions"
import { message, notification, Progress } from "antd"
import React from "react"
import { knowledgeTree } from "@api/knowledge"
import { Icon } from "@components/Common"
import { useHistory, useLocation } from "react-router-dom"
import { asyncSetMessageNumber, setMessageNumber, setMessages } from "@store/actions/common"
import { stringify } from "qs"

const getDocTreeDataApi = productApi(knowledgeTree)
const getDeptDataApi = productApi(queryDeptTreeData)
const getCityDataApi = productApi(sysDictCitys)
const getTagTreeDataApi = productApi(sysLabelList)
const getDeptDataWithRoleApi = productApi(queryDeptTreeDataWithRole)
const getProductCategoryApi = productApi(getProductCategory)
const getContactsByCustomerIdApi = productApi(customerContactList)

const delImportExcelConsumeOrderStatusApi = productApi(delImportExcelConsumeOrderStatus)

const getDictEnumApi = productApi(sysDictListRight)
const getCluesPoolApi = productApi(sysPoolList)
const getRoleListApi = productApi(sysRolelList)
const getProductTreeDataApi = productApi(productTree)
const getLqSearchConditions = productApi(lqSearchConditions)


export const transferDeptTreeDataWithRole = (data: TreeDataItem[]) => {
    const arr: TreeDataItem[] = []
    const deps = (data: TreeDataItem[], pid: any, pname: any) => data.forEach(v => {
        v.value = `${pid ? `${pid}-` : ''}${(v.type||'')[0] + v.value}`
        v.key = v.value
        v._name = (v.children && v.children.length) ? v.label : `${pname}(${v.label})`
        arr.push(v)
        v.children && deps(v.children || [], v.value, v.label||'')
    })
   deps(data, '', '')
   return arr
}

export const useTableHooks = (api: any, defaultParams: Record<string, any> = {}, isInitQuery: boolean = true) => {
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [lastParams, setLastParams] = useState({})

    const fetchApi = async (params: Record<string, any> = {}, callBack?: Function) => {
        setLoading(true)
        const { data } = await api({...defaultParams, ...params})
        setLastParams({...defaultParams, ...params})
        setLoading(false)
        const {pageCurrent: current, total, pageSize, values } = (callBack ? callBack(data||{}) : data) || {}
        setDataSource([...(values||[])])
        setPagination({current, pageSize, total})
    }

    isInitQuery && useEffect(() => {
        fetchApi()
    }, [])

    return {
        setDataSource,
        setLoading,
        setPagination,
        fetchApi,
        tableProps: {
            dataSource,
            loading,
            pagination,
            onChange: ({current: pageCurrent, pageSize}: any, filters: any, sorter: any) => {
                fetchApi({...lastParams, pageCurrent, pageSize})
            }
        }
    }
}

// 获取组织架构树数据
type DeptDataParam = {
    type?: number | string;  // 1: 部门; 2: 部门带人员
}
export const useDeptDataHooks: CustomerHooks<DeptDataParam> = (param = {}, isUseEffect = true, disabled?: DisabledRule) => {
    const [treeData, setTreeData] = useState<any[]>([])

    const getApi = async (param: DeptDataParam) => {
        return await getDeptDataApi(param, false, (data: any) => {
            transferTreeData(data, disabled)
            setTreeData(data)
        })
    }
    isUseEffect && useEffect(() => {
        getApi(param)
        return () => {
            setTreeData([])
        }
    },[])

    return [treeData, setTreeData, getApi]
}

// 获取文档树
type DocDataParam = {
    type?: number | string;  // 1: 解决方案 2: 客户案例 3: 问卷模版 4 帮助文档 5 问题 
}
export const useDocDataHooks: CustomerHooks<DeptDataParam> = (param = {}, isUseEffect = true, disabled?: DisabledRule) => {
    const [treeData, setTreeData] = useState<any[]>([])
    const getApi = async (param: DeptDataParam) => {
        return await getDocTreeDataApi(param, false, (data: any) => {
            transferTreeData(data, disabled)
            setTreeData(data||[])
        })
    }
    isUseEffect && useEffect(() => {
        getApi(param)
        return () => {
            setTreeData([])
        }
    },[])

    return [treeData, setTreeData, getApi]
}

// 获取人员角色树
export const useDeptDataWithRoleHooks: CustomerHooks<Object> = (param = {}, isUseEffect = true) => {
    const [treeData, setTreeData] = useState<any[]>([])

    const getApi = async (param: Object) => {
        return await getDeptDataWithRoleApi(param, false, (data: any) => {
            transferDeptTreeDataWithRole(data)
            setTreeData(data)
        })
    }
    isUseEffect && useEffect(() => {
        getApi(param)
        return () => {
            setTreeData([])
        }
    },[])

    return [treeData, setTreeData, getApi]
}

// 获取所有角色列表
export const useRoleListHooks: CustomerHooks<Object> = (param = {}, isUseEffect = true) => {
    const [treeData, setTreeData] = useState<any[]>([])

    const getApi = async (param: Object) => {
        const { data } = await getRoleListApi(param, false, (data: any) => {
            const options = (data||[]).map(({roleName: label, roleId: value}: any) => ({label, value}))
            isUseEffect && setTreeData(options)
        })
        return (data||[]).map(({roleName: label, roleId: value}: any) => ({label, value}))
    }
    isUseEffect && useEffect(() => {
        getApi(param)
        return () => {
            setTreeData([])
        }
    },[])

    return [treeData, setTreeData, getApi]
}

// 根据 customerId 获取联系人数据
type CantactsByCustomerParam = {
    customerId?: string | number;
    pageSize?: number;
    pageCurrent?: number;
}
export const useCantactsByCustomerIdHooks: CustomerHooks<CantactsByCustomerParam> = (param = {}, isUseEffect = true) => {
    const [options, setOptions] = useState<any[]>([])

    const getApi = async (param: CantactsByCustomerParam) => {
        return await getContactsByCustomerIdApi(param, false, (data: any) => {
            const { values } = data || {}
            const newOptions = values.map(({id: value, name: label, ...rest}: any) => ({label, value, ...rest}))
            setOptions(newOptions)
        })
    }
    
    isUseEffect && useEffect(() => {
        getApi(param)
        return () => {
            setOptions([])
        }
    }, [])

    return [options, setOptions, getApi]
}

// 线索池列表
export const useCluesPoolDataHooks: CustomerHooks<CantactsByCustomerParam> = (param = {}, isUseEffect = true) => {
    const [options, setOptions] = useState<any[]>([])

    const getApi = async (param: CantactsByCustomerParam = {}) => {
        const { data } = await getCluesPoolApi({pageSize: 1000, pageCurrent: 1, cluePoolStatus: 1, ...param}, false, (data: any) => {
            const { values } = data || {}
            const newOptions = (values||[]).map(({cluePoolName: label, id: value}: any) => ({label, value}))
            isUseEffect && setOptions(newOptions)
        })
        const { values } = data || {}
        return (values||[]).map(({cluePoolName: label, id: value}: any) => ({label, value}))
    }
    
    isUseEffect && useEffect(() => {
        getApi(param)
        return () => {
            setOptions([])
        }
    }, [])

    return [options, setOptions, getApi]
}

// 数据字典配置项
type DictEnumParam = {
    dictId?: string | number;
    pageSize?: number;
    pageCurrent?: number;
}
export const useDictEnumHooks: CustomerHooks<DictEnumParam> = (params = {}, isUseEffect = true) => {
    const [options, setOptions] = useState<any[]>([])

    const getApi = async (param: DictEnumParam = params) => {
        const { data } =  await getDictEnumApi({pageSize: 1000, pageCurrent: 1, status: 1, ...param}, false, (data: any) => {
            const { values } = data || {}
            const newOptions = (values||[]).map(({optionName: label, optionId: value}: any) => ({label, value}))
            isUseEffect && setOptions(newOptions)
        })
        const { values } = data || {}
        return (values||[]).map(({optionName: label, optionId: value}: any) => ({label, value}))
    }

    isUseEffect && useEffect(() => {
        getApi(params)
        return () => {
            setOptions([])
        }
    }, [])

    return [options, setOptions, getApi as any]
}

// 获取产品树
type ProductTreeDataParam = {

}
export const useProductTreeDataHooks: CustomerHooks<ProductTreeDataParam> = (param = {}, isUseEffect = true, disabled?: DisabledRule) => {
    const [treeData, setTreeData] = useState<any[]>([])

    const getApi = async (param: ProductTreeDataParam) => {
        return await getProductTreeDataApi(param, false, (data: any) => {
            transferTreeData(data, disabled)
            setTreeData(data)
        })
    }
    isUseEffect && useEffect(() => {
        getApi(param)
        return () => {
            setTreeData([])
        }
    },[])

    return [treeData, setTreeData, getApi]
}

// 获取省市区数据
type CityTreeDataParam = {

}
export const useCityTreeDataHooks: CustomerHooks<CityTreeDataParam> = (param = {}, isUseEffect = true, disabled?: DisabledRule) => {
    const [treeData, setTreeData] = useState<any[]>([])
    const getApi = async (param: CityTreeDataParam) => {
        return await getCityDataApi(param, false, (data: any) => {
            transferTreeData(data, disabled)
            setTreeData(data)
        })
    }
    isUseEffect && useEffect(() => {
        getApi(param)
        return () => {
            setTreeData([])
        }
    },[])

    return [treeData, setTreeData, getApi]
}

// 猎奇搜索条件
export const useLqSearchDataHooks: CustomerHooks<ProductTreeDataParam> = (param = {}, isUseEffect = true, disabled?: DisabledRule) => {
    const [searchData, setSearchData] = useState<any[]>([])

    const getApi = async (param: ProductTreeDataParam) => {
        return await getLqSearchConditions(param, false, (data: any) => {
            setSearchData(data)
        })
    }
    isUseEffect && useEffect(() => {
        getApi(param)
        return () => {
            setSearchData([])
        }
    },[])

    return [searchData, setSearchData, getApi]
}

// 猎奇搜索条件
export const useCommonApiHooks: CommonApiHooks<Object> = (options = {}, param = {}, isUseEffect = true) => {
    const [data, setData] = useState<any[]>([])
    const api = productApi((data?: object) => request({...options, data}))

    const getApi = async (params: Object) => {
        return await api(params, false, (data: any) => {
            setData(data)
        })
    }
    isUseEffect && useEffect(() => {
        getApi(param)
        return () => {
            setData([])
        }
    },[])

    return [data, setData, getApi]
}

// 权限判断的hooks
export const useHasAuthCode = () => {
    const { userInfo } = useSelector((state: StateType) => state)
    const { permissions } = userInfo || {}
    const hasAuthCode = (code: number | string) => (permissions||[]).includes(code)
    return [permissions, hasAuthCode]
}

// 公共 查询后端处理进度的hooks
export const useServerTaskProcess = () => {
    const { taskObj } = useSelector((state: StateType) => state) as any;
    const useRefTime = useRef<any>(null)
    const showMessage = useRef<any>(true)
    const dispatch = useDispatch();

    useEffect(() => {
        if ( taskObj.length ) {
            const loadingTask = (taskObj||[]).filter((task: any) => !task.finish).length   //计算正在进行中任务的数量
            let loadingNum = 0
            taskObj.forEach((element: any, idx: number) => {
                const { taskId ,taskName, url , percentage, finish } = element || {}
                const description = (percentage ? <Progress percent={percentage} /> : null)
                if (!finish) {
                    loadingNum++
                    const duration = (0.1 * loadingNum) + ((loadingNum === loadingTask) ? 3 : 0.1)
                    showMessage.current && notification['info']({
                        message: taskName + '--正在后台进行中',
                        key: taskId,
                        duration,
                        description,
                        onClose: () => {
                            showMessage.current = false
                            message.info('已隐藏弹窗，将在后台继续导出');
                        }
                    }); 
                } else if (finish) {
                    delImportExcelConsumeOrderStatusApi({ id: taskId}, false )
                    notification['success']({
                        message: taskName + '完成了',
                        key: taskId,
                        duration: 3,
                        description,
                    }); 
                    url && (window.location.href = url)
                }
            })
            loadingTask &&  (useRefTime.current = setTimeout(() => {
                dispatch(asyncSetGetConsumeOrderProcess())
            }, 10 * 1000))
            return () => {
                useRefTime.current && clearTimeout(useRefTime.current) 
                showMessage.current = false
            }
        }
    }, [taskObj])
}



// 返回维度二级下拉的list
export const dimensionList =  (options = {}, param = {}, isUseEffect = true) => {
    const [treeData, setTreeData] = useState<any>([])
    const getApi = async (param: CityTreeDataParam) => {
        return await getProductCategoryApi(param, false, (data: any) => {
            data.forEach((element: any) => {
                element.children = (element?.children||[]).map((item:any) => {
                    const { id, name } = item
                    return {
                       label: name,
                       value: id
                    }
                });
            });
            setTreeData(data)
        })
    }
    isUseEffect && useEffect(() => {
        getApi(param)
        return () => {
            setTreeData([])
        }
    },[])
    return [treeData, setTreeData, getApi]
}


export const mockItem = (n: number = 1) => new Array(n).fill({
    title: '文档标题',
    type: Math.floor((Math.random() * 10)%2),
}).map((v, idx) => ({...v, title: v.title + idx, id: v.id ? v.id : `zqs-${idx}`}))

export const useMyGetMessage = () => {
    let timer = useRef<any>(null)
    let askTimer = useRef<any>(null)
    const dispatch = useDispatch();
    const history = useHistory()
    const { messages } = useSelector((state: StateType) => state)

    const notificationList = (messageList: any) => {
        let thatItem: any, len = messageList.length;
        const messageQueue = () => {
            thatItem = messageList.shift()
            len = messageList.length
            const { type, title, taskOrNoticeId, id } = thatItem || {}
            const calcDuration = (len < 5 ? (6 - len): 0) * 3;
            notification.config({maxCount: 5})
            notification['info']({
                message: type === 1 ? '业务消息' : '系统公告' ,
                key: id,
                description: <a onClick={()=> clickMessage(thatItem)}>{title}</a>,
                placement: 'bottomRight',
                duration: calcDuration,
                icon:  <Icon type="icon-yangshengqi" style={{color: '#1890FF'}}/>
            });
        }
        timer.current = setInterval(() => {
            if (len) {
                messageQueue()
                clearInterval(askTimer.current)
                askTimer.current = null
            } else {
                clearInterval(timer.current)
                timer.current = null
            }
        }, 400)
    }
    const clickMessage = (data:any) => {
        const { id, msgType, msgId, customerName, title } = data
        history.push(`/oa/messageDetail?${stringify({id, msgId, msgType, customerName})}`)
    }
    useEffect(()=> {
        dispatch(asyncSetMessageList())
        if (!timer.current) {
            askTimer.current = setInterval(() => {
                dispatch(asyncSetMessageList())
            }, 5 * 60 * 1000)
        }
        return () => {
            clearInterval(timer.current)
            clearInterval(askTimer.current)
            timer.current = null
            askTimer.current = null
        }
    }, [])
    useEffect(() => {
        if (messages?.length) {
            !timer.current && notificationList(messages||[])
        } 
    }, [messages?.length])
    return null
}

export const useFullPath = () => {
    const { pathname, search } = useLocation();
    const fullPath = `${pathname}${search}`
    return fullPath
}

export const useFormDataCache = (key?: string) => {
    const { pathname, search } = useLocation();
    const fullPath = key || `${pathname}${search}`
    const setCache = (value: any) => {
        setSession(fullPath, value)
    }
    const getCache = (key?: string) => {
        return getSession(key||fullPath)
    }
    const removeCache = (key?: string) => { // 移除某一个key
        removeSession(key||fullPath)
    }
    const removeCacheKeys = (key?: string) => { // 移除key, 以及key: 相关的key
        const willRemoveKeys = Object.keys(sessionStorage).filter(sessionKey => sessionKey.startsWith(`${key}:`));
        [key, ...willRemoveKeys].forEach(removeCache)
    }
    return [getCache, setCache, removeCache, removeCacheKeys]
}


// 清除系统缓存
type StorageType = 'localStorage' | 'sessionStorage'
export const useClearCache = (storageType?: StorageType) => {
    const storage = window[storageType || 'localStorage']
    const reserveKeysRemove = (reserveKeys?: string[]) => {
        Object.keys(storage).forEach((key: string) => {
            if (!reserveKeys?.includes(key)) {
                removeStorage(key)
            }
        })
    }

    return reserveKeysRemove
}
