import { ProColumns } from "@ant-design/pro-table"
import { CustomerNameLinkRender, TableListItem } from "@components/Customer/List/data"
import { taskStatusOptions } from "@components/Oa/TaskCenter/data"
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index"
import { Progress, TreeSelect } from "antd"
import { stringify } from "qs"
import React from "react"
import { Link, useHistory } from "react-router-dom"
import { CardStuffSelect } from "."
import moment from "moment"
import { useSelector } from "react-redux"
import { StateType } from "@store/index"
import { amountFormat } from "@utils/index"

const ranges = {
    '今天': [moment(), moment()],
    '昨天': [moment().add(-1, 'days'), moment().add(-1, 'days')],
    '近7天': [moment().add(-7, 'days'), moment()],
    '本周': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
    '上周': [moment().startOf('isoWeek').add(-7, 'days'), moment().endOf('isoWeek').add(-7, 'days')],
    '本月': [moment().startOf('month'), moment().endOf('month')],
    '上月': [moment().startOf('month').add(-1, 'month'), moment().endOf('month').add(-1, 'month')],
    '本季': [moment().startOf('quarter'), moment().endOf('quarter')],
    '上季': [moment().startOf('quarter').add(-1, 'quarter'), moment().endOf('quarter').add(-1, 'quarter')],
    '本年': [moment().startOf('year'), moment().endOf('year')],
    '上年': [moment().startOf('year').add(-1, 'year'), moment().endOf('year').add(-1, 'year')],
}

export const ranges1 = {
    // '今天': [moment(), moment()],
    // '昨天': [moment().add(-1, 'days'), moment().add(-1, 'days')],
    // '近7天': [moment().add(-7, 'days'), moment()],
    // '本周': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
    // '上周': [moment().startOf('isoWeek').add(-7, 'days'), moment().endOf('isoWeek').add(-7, 'days')],
    // '本月': [moment().startOf('month'), moment().endOf('month')],
    // '上月': [moment().startOf('month').add(-1, 'month'), moment().endOf('month').add(-1, 'month')],
    'Q1': [moment().quarter(1).startOf('quarter'), moment().quarter(1).endOf('quarter')],
    'Q2': [moment().quarter(2).startOf('quarter'), moment().quarter(2).endOf('quarter')],
    'Q3': [moment().quarter(3).startOf('quarter'), moment().quarter(3).endOf('quarter')],
    'Q4': [moment().quarter(4).startOf('quarter'), moment().quarter(4).endOf('quarter')],
    '本季': [moment().startOf('quarter'), moment().endOf('quarter')],
    '上季': [moment().startOf('quarter').add(-1, 'quarter'), moment().endOf('quarter').add(-1, 'quarter')],
    // '本年': [moment().startOf('year'), moment().endOf('year')],
    // '上年': [moment().startOf('year').add(-1, 'year'), moment().endOf('year').add(-1, 'year')],
}



// 数据简报
export const dataReportList = [
    {
        label: '客户总数',
        param: 'customerTotal',
        value: (data: any) => <Link to={`/customer/list`}><a>{data?.customerTotal||0}</a></Link>
    },
    {
        label: '重点客户数',
        param: 'emphasisCustomerTotal',
        // value: ({emphasisCustomerTotal}: any) => amountFormat(emphasisCustomerTotal)
    },
    {
        label: '成交客户数',
        param: 'achieveCustomerTotal',
        // value: ({achieveCustomerTotal}: any) => amountFormat(achieveCustomerTotal)
    },
]

// 商机分析 统计
export const businessStatisticList = [
    {
        label: '落单Cash(元)',
        param: 'singleCashReceipts',
        value: ({singleCashReceipts}: any) => amountFormat(singleCashReceipts)
    },
    {
        label: '意向Cash(元)',
        param: 'estimatedCashIncome',
        value: ({estimatedCashIncome}: any) => amountFormat(estimatedCashIncome)
    },
    {
        label: '剩余意向Cash(元)',
        param: 'residualIntentionCashIncome',
        value: ({residualIntentionCashIncome}: any) => residualIntentionCashIncome < 0 ? 0 : amountFormat(residualIntentionCashIncome)
    },
    {
        label: '落单差价(元)',
        param: 'intentionSpreadIncome',
        value: ({intentionSpreadIncome}: any) => amountFormat(intentionSpreadIncome)
    },
    {
        label: '意向差价(元)',
        param: 'estimatedBetweenIncome',
        value: ({estimatedBetweenIncome}: any) => amountFormat(estimatedBetweenIncome)
    },
    {
        label: '剩余意向差价(元)',
        param: 'residualIntentionSpreadIncome',
        value: ({residualIntentionSpreadIncome}: any) => residualIntentionSpreadIncome < 0 ? 0 : amountFormat(residualIntentionSpreadIncome)
    },
]

// 已完成业绩 统计
export const doneStatisticList = [
    {
        label: '季度Cash目标（元）',
        param: 'quarterCash',
        value: ({quarterCash}: any) => amountFormat(quarterCash)
    },
    {
        label: '已落单Cash（元）',
        param: 'yetCash',
        value: ({yetCash}: any) => amountFormat(yetCash)
    },
    {
        label: 'Cash目标差额（元）',
        param: 'cashDifference',
        value: ({cashDifference}: any) => amountFormat(cashDifference)
    },
    {
        label: 'Cash目标完成率',
        param: 'cashTargetProb',
        value: ({cashTargetProb}: any) => amountFormat(cashTargetProb) + '%'
    },
    {
        label: '季度差价目标（元）',
        param: 'quarterTarget',
        value: ({quarterTarget}: any) => amountFormat(quarterTarget)
    },
    {
        label: '已落单差价（元）',
        param: 'yetDifference',
        value: ({yetDifference}: any) => amountFormat(yetDifference)
    },
    {
        label: '差价目标差额（元）',
        param: 'yetTarget',
        value: ({yetTarget}: any) => amountFormat(yetTarget)
    },
    {
        label: '差价目标完成率',
        param: 'yetTargetProb',
        value: ({yetTargetProb}: any) => amountFormat(yetTargetProb) + '%'
    },
]

// 未完成业绩 统计
export const unDoneStatisticList = [
    {
        label: '未落单意向Cash（元）',
        param: 'unDonecash',
        value: ({unDonecash}: any) => amountFormat(unDonecash)
        // value: 200
    },
    {
        label: '未落单意向差价（元）',
        param: 'unDoneDifference',
        value: ({unDoneDifference}: any) => amountFormat(unDoneDifference)
        // value: 200
    },
]


export const dataSelectOptions = [
    {
        label: '仅本人',
        value: 0
    },
    {
        label: '仅本部门',
        value: 3
    },
    {
        label: '本部门及下级部门',
        value: 4
    },
]

// 【个人商机， 部门商机】 列表
export const personBussnissColumns: () => ProColumns<any>[] = () => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, , request] = useDictEnumHooks({dictId: 13 }, false)
    return [
        {
            title: '人员姓名',
            dataIndex: 'userName',
            hideInSearch: true,
        },
        {
            title: '所属部门',
            dataIndex: 'deptName',
            hideInSearch: true
        },
        {
            title: '意向Cash',
            dataIndex: 'estimatedCashIncome',
            hideInSearch: true,
            sorter: true,
            render: (text: any, record: any) => amountFormat(record.estimatedCashIncome)
        },
        {
            title: '意向差价',
            dataIndex: 'estimatedBetweenIncome',
            hideInSearch: true,
            sorter: true,
            render: (text: any, record: any) => amountFormat(record.estimatedBetweenIncome)
        },
        {
            title: '落单Cash',
            dataIndex: 'singleCashReceipts',
            hideInSearch: true,
            sorter: true,
            render: (text: any, record: any) => amountFormat(record.singleCashReceipts)
        },
        {
            title: '落单差价',
            dataIndex: 'intentionSpreadIncome',
            hideInSearch: true,
            sorter: true,
            render: (text: any, record: any) => amountFormat(record.intentionSpreadIncome)
        },
        {
            title: '预计签单日期',
            dataIndex: 'estimatedSigningDate',
            valueType: 'dateRange',
            hideInTable: true,
            fieldProps: { 
                allowEmpty: [true, true],
                defaultValue: ranges['本月'],
                allowClear: true,
                ranges
            },
            formItemProps: {
                initialValue: ranges['本月']
            },
            search: {
                transform: (date) => {
                  return { 
                    startDate: date[0] ? moment(date[0]).format('YYYY-MM-DD') : undefined,
                    endDate: date[1] ? moment(date[1]).format('YYYY-MM-DD') : undefined,
                  };
                },
            },
        },
        {
            title: '选择人员',
            dataIndex: 'f',
            hideInTable: true,
            fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: deptWithUserTreeData
            },
            renderFormItem: (props) => <TreeSelect />,
            search: {
                transform: (ids) => {
                    const userIds = (ids||[]).map((v: string) => v.slice(1))
                    return { userIds }
                },
            },
        },
        {
            title: '项目阶段',
            dataIndex: 'salesStage',
            order: 42,
            width: 130,
            valueType: 'select',
            hideInTable: true,
            fieldProps: { mode: 'multiple' },
            params: { dictId: 20, pageCurrent: 1, pageSize: 1000},
            search: {
                transform: (salesStage) => {
                  return { 
                    salesStage
                  };
                },
            },
            request
        },
    ]    
}

// 【个人业绩， 部门业绩】 列表
export const qnAnalysisColumns: () => ProColumns<any>[] = () => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, , request] = useDictEnumHooks({dictId: 13 }, false)
    return [
        {
            title: '人员姓名',
            dataIndex: 'userName',
            hideInSearch: true
        },
        {
            title: '所属部门',
            dataIndex: 'deptName',
            hideInSearch: true
        },
        {
            title: '季度Cash进展',
            dataIndex: 'cash',
            hideInSearch: true,
            children: [
                {
                    title: 'Cash目标',
                    dataIndex: 'cashTarget',
                    hideInSearch: true,
                    sorter: true,
                    render: (text: any, record: any) => amountFormat(record.cashTarget)
                },
                {
                    title: '已落单Cash',
                    dataIndex: 'yetCash',
                    hideInSearch: true,
                    sorter: true,
                    render: (text: any, record: any) => amountFormat(record.yetCash)
                },
                {
                    title: '目标差额',
                    dataIndex: 'cashDifference',
                    hideInSearch: true,
                    sorter: true,
                    render: (text: any, record: any) => amountFormat(record.cashDifference)
                },
                {
                    title: '目标完成率',
                    dataIndex: 'cashTargetProb',
                    hideInSearch: true,
                    sorter: true,
                    render: (text: any, record: any) => amountFormat(record.cashTargetProb) + '%'
                },
                {
                    title: '剩余意向cash',
                    dataIndex: 'undoneCash',
                    hideInSearch: true,
                    sorter: true,
                    render: (text: any, record: any) => amountFormat(record.undoneCash)
                },
            ]
        },
        {
            title: '季度差价进展',
            dataIndex: 'pd',
            hideInSearch: true,
            children: [
                {
                    title: '差价目标',
                    dataIndex: 'quarterTarget',
                    hideInSearch: true,
                    sorter: true,
                    render: (text: any, record: any) => amountFormat(record.quarterTarget)
                },
                {
                    title: '已落单差价',
                    dataIndex: 'yetDifference',
                    hideInSearch: true,
                    sorter: true,
                    render: (text: any, record: any) => amountFormat(record.yetDifference)
                },
                {
                    title: '目标差额',
                    dataIndex: 'yetTarget',
                    hideInSearch: true,
                    sorter: true,
                    render: (text: any, record: any) => amountFormat(record.yetTarget)
                },
                {
                    title: '目标完成率',
                    dataIndex: 'yetTargetProb',
                    hideInSearch: true,
                    sorter: true,
                    render: (text: any, record: any) => amountFormat(record.yetTargetProb) + '%'
                },
                {
                    title: '剩余意向差价',
                    dataIndex: 'undoneDifference',
                    hideInSearch: true,
                    sorter: true,
                    render: (text: any, record: any) => amountFormat(record.undoneDifference)
                },
            ]
        },
        {
            title: '预计签单时间',
            dataIndex: 'estimatedSigningDate',
            valueType: 'dateRange',
            hideInTable: true,
            fieldProps: { 
                allowEmpty: [true, true],
                picker: 'month',
                ranges: ranges1,
                format: 'YYYY-MM',
                defaultValue: ranges['本季']
            },
            formItemProps: {
                initialValue: ranges['本季']
            },
            search: {
                transform: (date) => {
                    return { 
                        startDate: date[0] ? moment(date[0]).format('YYYY-MM-DD') : undefined,
                        endDate: date[1] ? moment(date[1]).endOf('M').format('YYYY-MM-DD') : undefined,
                    };
                },
            },
        },
        {
            title: '选择人员',
            dataIndex: 'f',
            hideInTable: true,
            fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: deptWithUserTreeData
            },
            renderFormItem: (props) => <TreeSelect />,
            search: {
                transform: (ids) => {
                    const userIds = (ids||[]).map((v: string) => v.slice(1))
                    return { userIds }
                },
            },
        },
        // {
        //     title: '项目阶段',
        //     dataIndex: 'salesStage',
        //     order: 42,
        //     width: 130,
        //     valueType: 'select',
        //     hideInTable: true,
        //     fieldProps: { mode: 'multiple' },
        //     params: { dictId: 20, pageCurrent: 1, pageSize: 1000},
        //     search: {
        //         transform: (salesStage) => {
        //           return { 
        //             salesStageList: salesStage
        //           };
        //         },
        //     },
        //     request
        // },
    ]    
}

export const qnManageColumns: (operate: any) => ProColumns<any>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, hasAuthCode] = useHasAuthCode()
    return [
        {
            title: '季度周期',
            dataIndex: 'month',
            valueType: 'dateRange',
            // hideInTable: true,
            fieldProps: { 
                allowEmpty: [true, true],
                picker: 'month',
                ranges: ranges1,
                format: 'YYYY-MM',
                defaultValue: ranges['本季']
            },
            formItemProps: {
                initialValue: ranges['本季']
            },
            search: {
                transform: (date) => {
                    return { 
                        startDate: date[0] ? moment(date[0]).format('YYYY-MM-DD') : undefined,
                        endDate: date[1] ? moment(date[1]).endOf('M').format('YYYY-MM-DD') : undefined,
                    };
                },
            },
            render: (text: any, record: any) => `${moment(record?.quarterStartDate).format('YYYY年MM月')}-${moment(record?.quarterEndDate).format('YYYY年MM月')}`
        },
        {
            title: (_, type) => type === 'form' ? '选择人员' : '姓名',
            dataIndex: 'userName',
            fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: deptWithUserTreeData
            },
            renderFormItem: (props) => <TreeSelect />,
            search: {
                transform: (ids) => {
                    const userIds = (ids||[]).map((v: string) => v.slice(1))
                    return { userIds }
                },
            },
            render: (text: any, record: any) => record?.userName 
        },
        {
            title: '所属部门',
            dataIndex: 'deptName',
            hideInSearch: true,
        },
        {
            title: '季度Cash目标(元)',
            dataIndex: 'quarterCash',
            hideInSearch: true,
            sorter: (a: any, b: any) => a.quarterCash - b.quarterCash,
            render: (text: any, record: any) => amountFormat(record.quarterCash)
        },
        {
            title: '季度差价目标(元)',
            dataIndex: 'quarterProfit',
            hideInSearch: true,
            sorter: (a: any, b: any) => a.quarterProfit - b.quarterProfit,
            render: (text: any, record: any) => amountFormat(record.quarterProfit)
        },
        {
            title: '操作',
            key: 'option',
            valueType: 'option',
            width: 180,
            // fixed: 'right',
            render: (text: any, record) => [
                hasAuthCode(2529) ? <a key="修改" onClick={() => operate?.('修改', record)}>修改</a> : null
            ],
        },
    ]    
}
